
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import {getCityListByName} from '@/API/localRegistration'
import { debounce } from 'lodash-es';
export default defineComponent({
  name: 'CitySelector',
  props: {
    title: {
      type: String as PropType<string>,
      default: 'name'
    },
    key: {
      type: String as PropType<string>,
      default: 'code'
    }
  },
  setup() {
  const data = ref<any>()

    const fetching = ref(false)
    const fetchData = debounce((name: string) => {
      fetching.value = true

      getCityListByName({name}).then((res: any) => {
        data.value = []
        data.value = res.map((info: any) => {
          return {
            label: info.name,
            value: info.name
          }
        }).filter((item: any)=> item.label !== null)
        fetching.value = false
      })
    }, 1000)

    return {
      fetching,
      fetchData,
      data,
    }
  }
})
