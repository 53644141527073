
import axios from 'axios';
import { useStore } from 'vuex';
import {
    defineComponent,
    ref,
    onMounted,
    reactive,
    computed,
    watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import DealerGroupSelector from '@/views/LocalRegistration/components/DealerGroupSelector.vue';
import CitySelector from '@/views/LocalRegistration/components/CitySelector.vue';
import DealerInfo from '@/views/LocalRegistration/components/DealerInfo.vue';
import ApplicableTypeClassSelector from '@/views/LocalRegistration/components/ApplicableTypeClassSelector2.vue';
import Upload from '@/views/LocalRegistration/components/Upload.vue';
import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import {
    getDetail,
    getCustomizeVinList,
    deleteCustomizeVin,
    getExemptionRuleVinList,
    saveContent,
    createContent,
} from '@/API/localRegistration';
import { getRegion } from '@/API/salesReporting/claimCheckingReport';
import downloadFile from '@/utils/claim/downloadFile';

enum Mode {
    VIEW,
    ADD,
    EDIT,
}

interface CustomizeType {
    code: null | string;
    name: null | string;
    description: null | string;
    eligibleFrom: null | string;
    eligibleTo: null | string;
}

interface ExemptionRuleType {
    code: null | string;
    name: null | string;
    description: null | string;
    eligibleFrom: null | string;
    eligibleTo: null | string;
    exemptionType: null | string;
    exemptionMethod: null | string;
    exemptionDeadline: null | string;
}

type UndefinedString = undefined | string;

export default defineComponent({
    components: {
        DealerInfo,
        DealerGroupSelector,
        CitySelector,
        ApplicableTypeClassSelector,
        CheckCircleOutlined,
        DownloadOutlined,
        Upload,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const bu = route.query.bu as string;
        const currentDataType = route.query.dataType as string;
        const activeKey = ref('Customize');
        activeKey.value = (route.query.activeKey as string) ?? 'Customize';

        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });

        const customize = reactive<CustomizeType>({
            code: null,
            name: null,
            description: null,
            eligibleFrom: null,
            eligibleTo: null,
        });

        const dealerGroup = ref<UndefinedString>(undefined);
        const dealerCode = ref<UndefinedString>(undefined);
        const swtCode = ref<UndefinedString>(undefined);
        const dealerName = ref<UndefinedString>(undefined);
        const city = ref<UndefinedString>(undefined);
        const typeClass = ref<UndefinedString>(undefined);

        // region  subregion
        const region = ref<string[]>([]);
        const subRegion = ref<string[]>([]);
        const regionList = ref<any>([]);
        const subRegionList = ref<any>([]);
        const store = useStore();
        const getSelectBuId = (buName: string) => {
            const buList = store.state.user.buList;
            const index = buList.findIndex(
                (item: any) => item.nameEn === buName
            );
            return buList[index]?.id;
        };
        const sortRegions = (regions: any) => {
            const sortArr: any[] = [[], [], [], [], []];
            const maps = {
                n: 0,
                e: 1,
                w: 2,
                s: 3,
            };
            regions.forEach((region: any) => {
                const firstLetter = (region
                    .regionEn[0] as string).toLocaleLowerCase();
                if (firstLetter in maps) {
                    sortArr[maps[firstLetter]].push(region);
                } else {
                    sortArr[4].push(region);
                }
            });
            return sortArr.flat() as any;
        };
        const getRegionList = () => {
            const buId = getSelectBuId(bu);
            getRegion({ buId }).then((res) => {
                const regions = sortRegions(res);
                regionList.value = regions;
            });
        };
        getRegionList();
        const getSelectRegionId = (regionName: string) => {
            const index = regionList.value.findIndex(
                (item: any) => item.regionEn === regionName
            );
            return regionList.value[index]?.id;
        };
        const getSubRegionList = () => {
            if (region.value.length === 0) return;
            const buId = getSelectBuId(bu);
            const getSubRegions = region.value.map((reg: any) => {
                const regionId = getSelectRegionId(reg);
                return Promise.resolve(
                    getRegion({ buId: `${buId}`, regionId: `${regionId}` })
                );
            });
            Promise.all(getSubRegions).then((res: any) => {
                subRegionList.value = sortRegions(res.flat());
            });
        };
        watch(region, (newRegion: any) => {
            if (newRegion.length === 0) {
                subRegionList.value = [];
                subRegion.value = [];
            }
        });
        const mode = ref(Mode.ADD);

        mode.value = route.params.mode === 'add' ? Mode.ADD : Mode.EDIT;
        customize.code = route.query.code as string;
        const basicId = route.query.basicId as string;

        //* customize Tab页内容
        const customizeColumnsAll = [
            {
                title: 'VIN',
                dataIndex: 'vin',
                type: 'vin',
            },
            {
                title: 'Retail Date',
                dataIndex: 'retailDate',
                type: 'vin',
            },
            {
                title: 'Dealer Code',
                dataIndex: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
            },
            {
                title: 'Applicable Type Class',
                dataIndex: 'applicableTypeClass',
            },
            {
                title: 'Dealer Group',
                dataIndex: 'dealerGroup',
            },
            {
                title: 'Region',
                dataIndex: 'region',
            },
            {
                title: 'Subregion',
                dataIndex: 'subRegion',
            },
            {
                title: 'City',
                dataIndex: 'city',
            },
            {
                title: 'Exemption',
                align: 'center',
                slots: { customRender: 'Exemption' },
            },
            {
                title: 'Operation',
                align: 'center',
                slots: { customRender: 'Operation' },
            },
        ];

        const customizeData = ref<any>([]);

        const showVin = ref(false);

        const customizeColumns = computed(() => {
            if (showVin.value) {
                return customizeColumnsAll;
            } else {
                return customizeColumnsAll.filter((col: any) => {
                    return !col.type;
                });
            }
        });

        const vin = ref<UndefinedString>(undefined);
        const searchCustomizeList = () => {
            const params = {
                applicableTypeClassList:
                    typeClass.value === undefined
                        ? undefined
                        : [typeClass.value],
                basicId,
                city: city.value,
                dealerCode: dealerCode.value,
                swtCode: swtCode.value,
                dealerGroup: dealerGroup.value,
                dealerName: dealerName.value,
                // eligibleFrom: '2022-01-19T09:30:30.466Z',
                // eligibleTo: '2022-01-19T09:30:30.466Z',
                regionList:
                    region.value.length === 0 ? undefined : region.value,
                subRegion:
                    subRegion.value.length === 0 ? undefined : subRegion.value,
                vin: vin.value,
            };
            getCustomizeVinList(params).then((res: any) => {
                customizeData.value = res;
            });
        };

        //* Batch exemption：点击后给当前页面的查询结果批量打豁免标签
        const vinList = ref<any>([]);
        const batchExemption = () => {
            if (customizeData.value.length === 0) return;
            const currentVinList = customizeData.value.map((data: any) => {
                data.isExemption = true;
                return {
                    vin: data.vin,
                };
            });
            vinList.value = [...vinList.value, ...currentVinList];
        };

        const reset = () => {
            dealerGroup.value = undefined;
            dealerCode.value = undefined;
            swtCode.value = undefined;
            dealerName.value = undefined;
            region.value = [];
            city.value = undefined;
            typeClass.value = undefined;
        };

        const exportCustomizeVin = () => {
            if (mode.value === Mode.ADD) {
                //TODO batchsize
                const exemptedData = customizeData.value.filter((data: any) => {
                    return !!data.isExemption;
                });
                const params = {
                    url: `/rvapi/lr/exemptionRuleVinDetail/exportCustomizeVinListForAdd`,
                    method: 'post',
                    params: exemptedData,
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            } else {
                const params = {
                    url: `/rvapi/lr/exemptionRuleVinDetail/exportCustomizeVinList?basicId=${basicId}`,
                    method: 'post',
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            }
        };

        const approvalFile = {
            approvalFileName: '',
            approvalFileUrl: '',
        };

        const beforeUpload = (file: any) => {
            const FILE_TYPE = ['application/pdf', 'image/jpeg'];
            const isSelectedType = FILE_TYPE.includes(file.type as string);
            if (!isSelectedType) {
                message.error('请上传PDF或JPG格式文件！');
            }
            return isSelectedType;
        };

        const uploadExemptionFile = (file: any, resolve: any) => {
            if (!beforeUpload(file)) return;
            const formData = new FormData();
            formData.append('file', file);
            axios({
                method: 'post',
                data: formData,
                url: `/rvapi/lr/file/upload`,
            }).then((res: any) => {
                approvalFile.approvalFileName = res.data.data.fileName;
                approvalFile.approvalFileUrl = res.data.data.fileUrl;
                resolve();
            });
        };

        const customizeSaveCheck = () => {
            let passCheck = true;
            if (
                approvalFile.approvalFileUrl === '' &&
                approvalFile.approvalFileName === ''
            ) {
                message.error('Please upload document');
                passCheck = false;
            }
            if (!customize.eligibleFrom || !customize.eligibleTo) {
                message.error('Please set eligible period');
                passCheck = false;
            }
            if (!customize.name) {
                message.error('Please set eligible name');
                passCheck = false;
            }
            return passCheck;
        };

        const saveCustomizeVin = () => {
            if (!customizeSaveCheck()) return;
            const params = {
                dataType: 1,
                approvalFileName: approvalFile.approvalFileName,
                approvalFileUrl: approvalFile.approvalFileUrl,
                bu: bu,
                code: customize.code,
                description: customize.description,
                eligibleFrom: customize.eligibleFrom,
                eligibleTo: customize.eligibleTo,
                name: customize.name,
                id: basicId,
                vinList: vinList.value,
            };
            const method = {
                1: createContent,
                2: saveContent,
            };
            method[mode.value](params).then((res: any) => {
                if (res === '') {
                    message.success('Save Successfully!');
                    router.go(-1);
                }
            });
        };

        //* exemption rule Tab页内容
        const exemptionRuleType = reactive<ExemptionRuleType>({
            code: null,
            name: null,
            description: null,
            eligibleFrom: null,
            eligibleTo: null,
            exemptionType: null,
            exemptionMethod: null,
            exemptionDeadline: null,
        });
        exemptionRuleType.code = route.query.code as string;

        const hasSelectedDeadline = computed(() => {
            return exemptionRuleType.exemptionDeadline === null ||
                exemptionRuleType.exemptionDeadline === ''
                ? false
                : true;
        });
        const exemptionRuleColumns = ref<any>([
            {
                title: 'VIN',
                dataIndex: 'vin',
            },
            {
                title: 'Retail Date',
                dataIndex: 'retailDate',
            },
            {
                title: 'Dealer Code',
                dataIndex: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
            },
            {
                title: 'Brand',
                dataIndex: 'brand',
            },
            {
                title: 'Type Class',
                dataIndex: 'typeClass',
            },
            {
                title: 'Registration Deadline',
                dataIndex: 'exemptionDeadline',
            },
            {
                title: 'Operation',
                align: 'center',
                slots: { customRender: 'Operation' },
            },
        ]);

        const exemptionRuleData = ref<any>([]);

        const isUploadVinFailed = computed(() => {
            return exemptionRuleData.value.find(
                (item: any) => item.errorMsg !== ''
            );
        });

        const searchExemptionRules = () => {
            const params = {
                code: exemptionRuleType.code,
                current: pagination.currentPage,
                size: pagination.pageSize,
            };
            getExemptionRuleVinList(params).then((res: any) => {
                exemptionRuleData.value = res.content;
                pagination.total = res.totalElements;
                if (isUploadVinFailed.value) {
                    exemptionRuleColumns.value.push({
                        title: 'Fail reason',
                        dataIndex: 'errorMsg',
                    });
                }
            });
        };

        const changeTabs = () => {
            approvalFile.approvalFileUrl = '';
            approvalFile.approvalFileName = '';
            if (
                activeKey.value === 'Exemption Rule' &&
                mode.value === Mode.EDIT
            ) {
                searchExemptionRules();
            }
        };

        const deleteCurrentRow = (record: any) => {
            const params = {
                ids: [record.id],
                dataType: record.dataType,
            };
            deleteCustomizeVin(params).then((res: any) => {
                if (activeKey.value === 'Customize') {
                    searchCustomizeList();
                } else {
                    searchExemptionRules();
                }
            });
        };

        const viewFromExemption = () => {
            if (activeKey.value === 'Exemption Rule') {
                searchExemptionRules();
            }
        };

        const exportExemptionRule = () => {
            if (mode.value === Mode.ADD) {
                const params = {
                    url: `/rvapi/lr/exemptionRuleVinDetail/exportExemptionRuleVinListForAdd`,
                    method: 'post',
                    params: exemptionRuleData.value,
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            } else {
                const params = {
                    url: `/rvapi/lr/exemptionRuleVinDetail/exportExemptionRuleVinList?basicId=${basicId}`,
                    method: 'post',
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            }
        };

        const judgeVanColumns = () => {
            if (bu === 'VAN') {
                customizeColumnsAll.splice(3, 0, {
                    title: 'SWT Code',
                    dataIndex: 'swtCode',
                });
                exemptionRuleColumns.value.splice(3, 0, {
                    title: 'SWT Code',
                    dataIndex: 'swtCode',
                });
            }
        };
        judgeVanColumns();

        // 导入接口调用
        const unchangableType = ref(false);
        const uploadVin = (file: any, resolve: any) => {
            const formData = new FormData();
            formData.append('file', file);
            const url =
                basicId === undefined
                    ? `/rvapi/lr/exemptionRuleVinDetail/import?exemptionType=${exemptionRuleType.exemptionType}&exemptionCode=${exemptionRuleType.code}`
                    : `/rvapi/lr/exemptionRuleVinDetail/import?basicId=${basicId}&exemptionType=${exemptionRuleType.exemptionType}&exemptionCode=${exemptionRuleType.code}`;
            axios({
                method: 'post',
                data: formData,
                // import excel for exemption rule
                url,
            }).then((res: any) => {
                unchangableType.value = true;
                searchExemptionRules();
                resolve();
            });
        };

        const exemptionRuleSaveCheck = () => {
            let passCheck = true;
            if (
                approvalFile.approvalFileUrl === '' &&
                approvalFile.approvalFileName === ''
            ) {
                message.error('Please upload document');
                passCheck = false;
            }
            if (
                !exemptionRuleType.eligibleFrom ||
                !exemptionRuleType.eligibleTo
            ) {
                message.error('Please set eligible period');
                passCheck = false;
            }
            if (!exemptionRuleType.name) {
                message.error('Please set eligible name');
                passCheck = false;
            }
            if (!exemptionRuleType.exemptionType) {
                message.error('Please set eligible type');
                passCheck = false;
            }
            if (!exemptionRuleType.exemptionMethod) {
                message.error('Please set eligible method');
                passCheck = false;
            }
            if (!hasSelectedDeadline.value && isUploadVinFailed.value) {
                message.error('Has error VIN');
                passCheck = false;
            }
            return passCheck;
        };

        const saveExemptionRule = () => {
            if (!exemptionRuleSaveCheck()) return;
            const params = {
                approvalFileName: approvalFile.approvalFileName,
                approvalFileUrl: approvalFile.approvalFileUrl,
                bu: bu,
                code: exemptionRuleType.code,
                dataType: 2,
                description: exemptionRuleType.description,
                eligibleFrom: exemptionRuleType.eligibleFrom,
                eligibleTo: exemptionRuleType.eligibleTo,
                exemptionDeadline: exemptionRuleType.exemptionDeadline,
                exemptionMethod: exemptionRuleType.exemptionMethod,
                exemptionType: exemptionRuleType.exemptionType,
                name: exemptionRuleType.name,
                id: basicId,
                vinList: hasSelectedDeadline.value
                    ? []
                    : exemptionRuleData.value.map((item: any) => {
                          return {
                              vin: item.vin,
                              exemptionDeadline: item.exemptionDeadline,
                          };
                      }),
            };
            const method = {
                1: createContent,
                2: saveContent, //! 用不上了
            };
            method[mode.value](params).then((res: any) => {
                message.success('Save Successfully!');
                router.go(-1);
            });
        };

        const handleCancel = () => {
            router.go(-1);
        };

        const visibleUploadVinDrawer = ref(false);

        let isDataByUploadVin = false;
        const handleUploadVin = () => {
            if (!exemptionRuleType.exemptionType) {
                message.error('Please set eligible type');
                return;
            }
            visibleUploadVinDrawer.value = true;
            isDataByUploadVin = true;
        };

        const deleteExemptionRuleColumns = (record: any) => {
            deleteCurrentRow(record);
        };

        const visibleUploadExemptionFileDrawer = ref(false);

        const handleUploadExemptionFile = () => {
            visibleUploadExemptionFileDrawer.value = true;
        };

        //更改每页size
        const changePageSize = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page;
            searchExemptionRules();
        };

        //更改分页组件，包括：直接跳转到指定页，上一页下一页
        const changePagination = (page: number, pagesize: number) => {
            pagination.pageSize = pagesize;
            pagination.currentPage = page;
            searchExemptionRules();
        };

        onMounted(() => {
            if (basicId) {
                getDetail({ id: basicId }).then((res: any) => {
                    customize.name = res.name;
                    customize.description = res.description;
                    customize.eligibleFrom = res.eligibleFrom;
                    customize.eligibleTo = res.eligibleTo;
                    exemptionRuleType.name = res.name;
                    exemptionRuleType.description = res.description;
                    exemptionRuleType.eligibleFrom = res.eligibleFrom;
                    exemptionRuleType.eligibleTo = res.eligibleTo;
                    exemptionRuleType.exemptionType = res.exemptionType;
                    exemptionRuleType.exemptionMethod = res.exemptionMethod;
                    exemptionRuleType.exemptionDeadline = res.exemptionDeadline;
                });
            }
            viewFromExemption();
        });

        const downloadTemplate = () => {
            const params = {
                url: `rvapi/lr/exemptionRuleVinDetail/downloadVinTemplate`,
                method: 'post',
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        return {
            currentDataType,
            dealerGroup,
            dealerCode,
            swtCode,
            dealerName,
            region,
            subRegion,
            city,
            typeClass,
            regionList,
            subRegionList,
            getSubRegionList,
            activeKey,
            exemptionRuleType,
            customize,
            mode,
            customizeColumns,
            customizeData,
            exemptionRuleColumns,
            exemptionRuleData,
            handleCancel,
            visibleUploadVinDrawer,
            handleUploadVin,
            visibleUploadExemptionFileDrawer,
            handleUploadExemptionFile,
            reset,
            searchCustomizeList,
            batchExemption,
            deleteCurrentRow,
            changeTabs,
            uploadVin,
            uploadExemptionFile,
            exportCustomizeVin,
            exportExemptionRule,
            saveCustomizeVin,
            saveExemptionRule,
            bu,
            showVin,
            vin,
            hasSelectedDeadline,
            isUploadVinFailed,
            unchangableType,
            downloadTemplate,
            deleteExemptionRuleColumns,
            pagination,
            changePageSize,
            changePagination,
        };
    },
});
