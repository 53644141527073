
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import { debounce } from 'lodash-es';
import {
    searchDealerByCodeAndName,
} from '@/API/localRegistration';

interface Params {
  code?: string;
  dealerNameCn?: string;
}

export default defineComponent({
  name: 'DealerInfo',
  props: {
    title: {
      type: String as PropType<string>,
      default: 'name'
    },
    key: {
      type: String as PropType<string>,
      default: 'code'
    },
    type: {
      type: String as PropType<string>,
      default: '',
      required: true
    }
  },
  setup(props) {
    const data = ref<any>()

    const fetching = ref(false)
    const fetchData = debounce((value: string) => {
      fetching.value = true

      const params: any = {}
      const typeMapToParams = {
        'dealerNameCn': 'dealerNameCn',
        'swtCode':"code",
        'dealerCode':"code"
      }
      params[typeMapToParams[props.type]] = value
      searchDealerByCodeAndName(params).then((res: any) => {
        data.value = []
        const dealerInfos = res.data.map((dealer: any)=> {
          return dealer.dealerInfo
        })
        data.value = dealerInfos.map((info: any) => {
          return {
            label: info[props.type],
            value: info[props.type]
          }
        }).filter((item: any)=> item.label !== null)
        fetching.value = false
      })
    }, 1000)

    return {
      fetching,
      fetchData,
      data,
    }
  }
})
