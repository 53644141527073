import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    "v-bind": _ctx.$attrs,
    "show-search": "",
    placeholder: "Please select",
    style: {"width":"100%"},
    "filter-option": false,
    "not-found-content": _ctx.fetching ? undefined : null,
    options: _ctx.data,
    onSearch: _ctx.fetchData
  }, _createSlots({ _: 2 }, [
    (_ctx.fetching)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ])
        }
      : undefined
  ]), 1032, ["v-bind", "not-found-content", "options", "onSearch"]))
}